import React, { useState, useEffect, useContext } from "react";
import instance from "../axios/global";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import ProjectContextProvider from "../context/projectContext";
import { Route, Routes } from "react-router-dom";
import Homepage from "./Homepage.js";
import Settings from "./Settings";
import Indexation from "./Indexation";
import CreationProjet from "./CreationProjet";
import SuiviIndexation from "./SuiviIndexation";
import Inspection from "./Inspection";
import SuiviInspection from "./SuiviInspection";
import Documentation from "./Documentation";
import RequireAuth from "../hooks/RequireAuth";
import { AuthContext } from "../auth/auth";

const Accueil = () => {
  const [allProjects, setAllProjects] = useState([]); // État pour stocker la liste des projets
  const [selectedProject, setSelectedProject] = useState(""); // Gérer l'état du projet sélectionné
  const { authState, setAuthState } = useContext(AuthContext);
  // ... autres variables d'état et fonctions

  // Charger la liste des projets initiale
  useEffect(() => {
    const fetchProjects = async () => {
      // Charger la liste des projets depuis votre API
      try {
        // console.log("authState.id :>> ", authState.id);
        const res = await instance.get(`/project/all/${authState.id}`);
        setAllProjects(res?.data);
        if (!selectedProject && res?.data.length > 0) {
          setSelectedProject(res.data[0].id);
          console.log("res.data[0]ICICICICI :>> ", res.data);
        }
      } catch (error) {
        console.log("Erreur lors du chargement des projets : ", error);
      }
    };
    fetchProjects();
  }, [authState.id, selectedProject, setAllProjects]);

  const handleProjectChange = (newProject) => {
    setSelectedProject(newProject);
  };

  console.log("authState form accueil:>> ", authState);
  return (
    <div className="accueil">
      <ProjectContextProvider>
        <Sidebar selectedProject={selectedProject} />
        <div className="content">
          <Navbar
            selectedProject={selectedProject}
            onProjectChange={handleProjectChange}
            allProjects={allProjects}
          />
          <section className="section-switch-nav">
            <div className="div-switch">
              <Routes>
                <Route element={<RequireAuth />}>
                  <Route
                    path="/home"
                    element={<Homepage selectedProject={selectedProject} />}
                  ></Route>
                  <Route path="/documentation" element={<Documentation />} />
                  <Route
                    path="/parametres/:id"
                    element={
                      <Settings setSelectedProject={setSelectedProject} />
                    }
                  ></Route>
                  <Route
                    path="/indexation/:id"
                    element={<Indexation />}
                  ></Route>
                  <Route
                    path="suivi-indexation/:id"
                    element={<SuiviIndexation />}
                  ></Route>
                  <Route path="/inspection/:id" element={<Inspection />} />
                  <Route
                    path="suivi-inspection/:id"
                    element={<SuiviInspection />}
                  />
                  <Route
                    path="/creerprojet"
                    element={
                      <CreationProjet setSelectedProject={setSelectedProject} />
                    }
                  ></Route>
                </Route>
              </Routes>
            </div>
          </section>
        </div>
      </ProjectContextProvider>
    </div>
  );
};

export default Accueil;
