import React from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
const NavIndexation = () => {
  const projectId = useParams();
  return (
    <div className="nav-indexation">
      <NavLink to={`/indexation/${projectId.id}`}>Indexer mes URLs</NavLink>
      <NavLink to={`/suivi-indexation/${projectId.id}`}>
        Suivi Indexation
      </NavLink>
    </div>
  );
};

export default NavIndexation;
