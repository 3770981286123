import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import instance from "../axios/global";
import { toast } from "react-toastify";
import { AuthContext } from "../auth/auth";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mantine/core";
import info from "../assets/info.svg";

const CreationProjet = ({ setSelectedProject }) => {
  const [projectName, setProjectName] = useState("");
  const navigate = useNavigate();
  const [apiKey, setApiKey] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const { authState, setAuthState } = useContext(AuthContext);

  const clientSchema = yup.object().shape({
    name: yup.string().required("Veuillez donner un nom à ce projet."),
    website_url: yup
      .string()
      .test(
        "is-valid-website-url",
        'Veuillez entrer un sc-domain: ou une URL valide commençant par "https://" et se terminant par "/"',
        function (value) {
          if (value.startsWith("sc-domain:")) {
            return true;
          }
          if (value.startsWith("https://") && value.endsWith("/")) {
            return true;
          }
          return false;
        }
      )
      .min(3, "L'Url ou domaine du projet doit comporter au moins 3 caractères")
      .max(45, "L'Url ou domaine du projet ne doit pas exceder 25 caractères")
      .required("Veuillez renseigner le domaine ou l'url du projet"),
    apiKey: yup
      .string()
      .test(
        "contains-private-and-project",
        "Vérifiez votre clé API, elle semble incorrect",
        function (value) {
          return (
            value.includes("private_key_id") &&
            value.includes("project_id") &&
            value.includes("private_key")
          );
        }
      )
      .required(
        "L'espace est immense, mais cette clé est essentielle. Veuillez la saisir."
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(clientSchema),
  });

  const handleSubmitProject = async () => {
    // e.preventDefault();
    console.log("authState.id important:>> ", authState.id);
    try {
      // Étape 1: Créer le projet
      const projectFormData = new URLSearchParams();
      projectFormData.append("name", projectName);
      projectFormData.append("website_url", websiteUrl);
      projectFormData.append("userId", authState.id);

      const projectResponse = await instance.post(
        "/project/create",
        projectFormData
      );
      console.log("projectResponse :>> ", projectResponse);
      console.log(
        "projectReponse DATAaaaa:>> ",
        projectResponse.data.projectId
      );
      const projectId = projectResponse.data.projectId;
      console.log("projectId :>> ", projectId);
      // Étape 2: Créer la clé API
      const apiKeyFormData = new URLSearchParams();
      apiKeyFormData.append("key", apiKey);
      apiKeyFormData.append("projectId", projectId);

      if (!apiKey) {
        toast.error("La clé API ne peut pas être vide.", {
          position: toast.POSITION.TOP_LEFT,
        });
        return;
      }

      await instance.post("/key/create", apiKeyFormData);
      setSelectedProject(projectId);
      navigate("/home");
      toast.success("Projet lancé dans l'espace avec succès !", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_LEFT,
      });
      console.error("Error: ", error);
    }
  };
  //   //e.preventDefault();
  //   const formData = new URLSearchParams();
  //   formData.append("name", projectName);
  //   formData.append("website_url", websiteUrl);
  //   formData.append("api_key", apiKey);
  //   formData.append("userId", authState.id);

  //   await instance
  //     .post(`/project/create`, formData)
  //     .then((res) => {
  //       toast.success("Votre nouveau a bien été crée.", {
  //         position: toast.POSITION.TOP_CENTER,
  //         className: "toast-message",
  //       });
  //     })
  //     .catch((error) => {
  //       toast.error(error.response.data.error, {
  //         position: toast.POSITION.TOP_LEFT,
  //       });
  //       console.log("err :>> ", error);
  //     });
  // };

  // const handleSubmitProject = async (e) => {
  //   e.preventDefault();
  //   const formData = new URLSearchParams();
  //   formData.append("name", projectName);
  //   formData.append("website_url", websiteUrl);
  //   formData.append("api_key", apiKey);
  //   formData.append("userId", authState.id);

  //   try {
  //     const response = await instance.post(`/project/create`, formData);
  //     const projectId = response.data.projectId; // Obtenez l'ID du projet depuis la réponse
  //     // Continuez avec la création de la clé API en utilisant projectId
  //     setApiKey(projectId);
  //     toast.success("Votre nouveau projet a bien été créé.", {
  //       position: toast.POSITION.TOP_CENTER,
  //       className: "toast-message",
  //     });
  //   } catch (error) {
  //     toast.error(error.response.data.error, {
  //       position: toast.POSITION.TOP_LEFT,
  //     });
  //     console.log("err :>> ", error);
  //   }
  // };

  // const handleSubmitKeys = async (e) => {
  //   const formData = new URLSearchParams();
  //   formData.append("key", apiKey);
  //   formData.append("projectId", authState.id);

  //   await instance
  //     .post(`/key/create`, formData)
  //     .then((res) => {})
  //     .catch((error) => {
  //       toast.error(error.response.data.error, {
  //         position: toast.POSITION.TOP_LEFT,
  //       });
  //       console.log("err :>> ", error);
  //     });
  // };

  // console.log("apiKey :>> ", apiKey);
  // console.log("projectName :>> ", projectName);
  // console.log("authState.id :>> ", authState.id);
  // console.log("websiteUrl :>> ", websiteUrl);
  return (
    <div className="creation-projet-page">
      <h1>Créer un nouveau projet</h1>
      <h2>Tous les paramètres de votre projet</h2>
      <form action="" onSubmit={handleSubmit(handleSubmitProject)}>
        <div className="container-name-website">
          <div className="label-input">
            <label htmlFor="">Nom du projet</label>
            <input
              type="text"
              name="name"
              id="name"
              {...register("name", {
                required: "Required",
              })}
              onChange={(e) => {
                setProjectName(e.target.value);
              }}
            />
            <span className="warning-msg">
              {errors.name && errors.name.message}
            </span>
          </div>

          <div className="label-input" style={{ marginLeft: "30px" }}>
            <label htmlFor="">
              Domaine ou URL{" "}
              <Tooltip
                multiline
                w={320}
                withArrow
                transitionProps={{ duration: 200 }}
                color="dark"
                label="→ Si sur ma search console, mon site a été entré en tant que “domaine”, je déclare dans les parametres de mon projet sc-domain: suivi du nom de domaine. exemple : sc-domain:onlyrooftop.com
→ Si j’ai une url, je l’a copie a l’identique. exemple : https://onlyrooftop.com/ (Le '/' final est important pour garantir la correspondance correcte.)"
              >
                <img src={info} alt="tooltip" style={{ marginBottom: "4px" }} />
              </Tooltip>
            </label>

            <input
              type="text"
              name="website_url"
              id="website_url"
              {...register("website_url", {
                required: "Required",
              })}
              onChange={(e) => {
                setWebsiteUrl(e.target.value);
              }}
            />
            <span className="warning-msg">
              {errors.website_url && errors.website_url.message}
            </span>
          </div>
        </div>
        <div className="container-textarea">
          <label htmlFor="">Clé API d'indexation google</label>
          <textarea
            type="text"
            name="apiKey"
            id="apiKey"
            {...register("apiKey", {
              required: "Required",
            })}
            onChange={(e) => {
              setApiKey(e.target.value);
            }}
          ></textarea>
          <span className="warning-msg">
            {errors.apiKey && errors.apiKey.message}
          </span>
          <p>
            Pour utiliser l’API d’indexation de Google et générer votre fichier
            clé JSON, veuillez suivre
            <span
              className="guide-link"
              onClick={() => navigate("/documentation")}
            >
              notre documentation
            </span>
          </p>
        </div>

        <button type="submit">Créer un nouveau projet</button>
      </form>
    </div>
  );
};

export default CreationProjet;
