import React, { useEffect, useState } from "react";
import useProjectContext from "../hooks/useProjectContext";
import NavIndexation from "../components/NavIndexation";
import { useParams } from "react-router-dom";
import instance from "../axios/global";
import { Loader } from "@mantine/core";
import success from "../assets/success.svg";
import iconerror from "../assets/error.svg";

const Indexation = () => {
  const projectId = useParams();
  const [urls, setUrls] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [invalidUrls, setInvalidUrls] = useState([]);
  const [parsedUrls, setParsedUrls] = useState([]);
  const {
    projectName,
    setProjectName,
    websiteUrl,
    setWebsiteUrl,
    apiKey,
    setApiKey,
  } = useProjectContext();

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/project/oneproject/${projectId.id}`);
        setProjectName(res?.data.name);
        setWebsiteUrl(res?.data.website_url);

        const apiKeyResponse = await instance.get(
          `/key/byproject/${projectId.id}`
        );
        console.log("apiKeyResponse :>> ", apiKeyResponse);
        setApiKey(apiKeyResponse?.data.key);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchSettings();
  }, [projectId.id]);

  useEffect(() => {
    setParsedUrls(urls.split("\n").filter((url) => url.trim() !== ""));
  }, [urls]);

  const isValidUrl = (url) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(url);
  };

  const isMatchingWebsiteUrl = (url, websiteUrl) => {
    // Si le websiteUrl commence par "sc-domain:", vérifiez si l'URL contient la partie après "sc-domain:"
    if (websiteUrl.startsWith("sc-domain:")) {
      const domainPart = websiteUrl.slice("sc-domain:".length);
      return url.includes(domainPart);
    } else {
      // Si le websiteUrl n'est pas une URL complète, vérifiez si l'URL contient le websiteUrl
      return url.includes(websiteUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const parsedUrls = urls.split("\n").filter((url) => url.trim() !== "");

      const invalidUrls = [];

      const validParsedUrls = parsedUrls.filter((url) => {
        const isValid =
          isMatchingWebsiteUrl(url, websiteUrl) && isValidUrl(url);
        if (!isValid) {
          invalidUrls.push(url);
        }

        return isValid;
      });

      if (parsedUrls.length > 200) {
        setError("Vous ne pouvez soumettre que 200 URLs par jour");
        return;
      }
      setLoading(true);
      setMessage("Envoi des URLs pour indexation...");
      setError(null);
      console.log("parsedUrls.length :>> ", parsedUrls.length);

      const remainingQuota = await instance.get(
        `/project/daily-indexation-quota/${projectId.id}`
      );

      if (validParsedUrls.length > remainingQuota) {
        setLoading(false);
        setError(
          "Limite quotidienne du quota d'inspection atteinte pour ce projet."
        );
        return; // Arrêtez ici si le quota est dépassé
      }

      const response = await instance.post(`/urls/indexing-urls`, {
        projectId: projectId.id,
        apiKey,
        urls: validParsedUrls,
      });

      for (const url of validParsedUrls) {
        try {
          // Appel à la fonction pour réduire le quota
          const quotaReductionResult = await instance.put(
            `/project/reduce-indexation/${projectId.id}`,
            { count: 1 }
          );

          if (quotaReductionResult.data === "QUOTA_REDUCED") {
            console.log(`Quota réduit avec succès pour l'URL : ${url}`);
          } else if (quotaReductionResult.data === "QUOTA_EXCEEDED") {
            console.log(`Quota dépassé pour l'URL : ${url}`);
            // Vous pouvez traiter le cas où le quota est dépassé pour une URL spécifique ici
          }
        } catch (error) {
          console.log(
            `Erreur lors de la réduction du quota pour l'URL : ${url}`,
            error
          );
        }
      }
      setInvalidUrls(invalidUrls);
      setLoading(false);
      setMessage(response.data.message);

      // if (response.data.invalidUrls && response.data.invalidUrls.length > 0) {
      //   setInvalidUrls(response.data.invalidUrls);
      // } else {
      //   setInvalidUrls([]); // Réinitialiser les URLs invalides s'il n'y en a pas
      // }
      console.log("isMatchingWebsiteUrl() :>> ", validParsedUrls);
      console.log("invalidUrls after :>> ", invalidUrls);
    } catch (error) {
      setLoading(false);
      setMessage(null);
      let errorData; // Déclaration de errorData en dehors du bloc if

      if (error.response && error.response.data) {
        errorData = error.response.data;
        if (errorData.error) {
          setError("Erreur lors de l'envoi de vos URLs : " + errorData.error);
        } else {
          setError(
            "Erreur lors de l'envoi de vos URLs : " + JSON.stringify(errorData)
          );
        }
      } else {
        errorData = error; // Affectation de error à errorData ici
        setError("Erreur lors de l'envoi de vos URLs : " + errorData);
      }

      // Handle request errors
      console.error(error);
    }
  };

  return (
    <div className="indexation-page">
      <div className="title-nav">
        <h1>Indexation</h1>
        <h2>Envoyer ici vos URLs à indexer.</h2>
        <NavIndexation />
      </div>

      <div className="single-page-app">
        <section>
          <form onSubmit={handleSubmit}>
            {/* <div className="container-label-input">
              <h1>Space Indexer</h1>
              <label>Votre clé API d'indexation</label>
              <textarea
                id="apiKey"
                className="input-textarea"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              ></textarea>
            </div> */}

            <div className="container-textarea">
              <label>Coller vos urls ici (200 urls par jour maximum)</label>
              <textarea
                id="urls"
                value={urls}
                onChange={(e) => setUrls(e.target.value)}
              ></textarea>
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <Loader color="violet" size="lg" />
              </div>
            ) : (
              <button type="submit" disabled={!apiKey || !urls || loading}>
                Décollage pour indexation
              </button>
            )}
          </form>
          {message && (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                maxWidth: "800px",
                color:
                  message === "Envoi des URLs pour indexation..."
                    ? "black"
                    : "#4e8565",
              }}
            >
              {message !== "Envoi des URLs pour indexation..." && (
                <img src={success} alt="done" />
              )}
              <span style={{ marginLeft: "5px" }}> {message}</span>
            </p>
          )}
          {error && (
            <p
              className="error-msg"
              style={{
                display: "flex",
                alignItems: "center",
                maxWidth: "800px",
              }}
            >
              <img src={iconerror} alt="error" />
              <span style={{ marginLeft: "5px" }}>{error}</span>
            </p>
          )}
          {invalidUrls.length > 0 && (
            <div className="invalid-urls">
              <h3>
                <img src={iconerror} alt="invalid" />
                URLs invalides :
              </h3>
              <ul>
                {invalidUrls.map((url, index) => (
                  <li key={index}>{url}</li>
                ))}
              </ul>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default Indexation;
