import React, { useEffect, useState, useContext, useRef } from "react";
import instance from "../axios/global";
import { AuthContext } from "../auth/auth";
import Stats from "../components/Stats";
import Chart from "chart.js/auto"; // Importez Chart.js
import satellite from "../assets/satellite.svg";
import rocket from "../assets/rocker.svg";
import search from "../assets/searching.svg";

const Homepage = ({ selectedProject }) => {
  const { authState, setAuthState } = useContext(AuthContext);
  const [project, setProject] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeSaved, setTimeSaved] = useState(0);
  const [indexationQuota, setIndexationQuota] = useState({ used: 0, max: 200 });
  const [inspectionQuota, setInspectionQuota] = useState({
    used: 0,
    max: 2000,
  });
  const [totalIndexedUrls, setTotalIndexedUrls] = useState("");
  const [totalInspectedUrls, setTotalInspectedUrls] = useState("");
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  console.log("selectedProject :>> ", selectedProject);

  useEffect(() => {
    instance
      .get("user/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          localStorage.setItem("user", JSON.stringify(res.data));
          console.log("res.dataAAAAAA :>> ", res.data);
          setAuthState({
            name: res.data.name,
            email: res.data.email,
            id: res.data.id,
            status: true,
          });
        }
      });
  }, []);

  useEffect(() => {
    if (!selectedProject) {
      // L'utilisateur n'a pas encore sélectionné de projet, ne rien afficher.
      return;
    }
    const fetchProjects = async () => {
      setLoading(true);
      try {
        const res = await instance.get(
          `/project/oneproject/${selectedProject}`
        );
        setProject(res?.data);
        instance
          .get(`/project/daily-indexation-quota/${selectedProject}`)
          .then((res) => {
            console.log("res.data :>> ", res?.data);
            setIndexationQuota({
              used: res.data.dailyIndexationQuota,
              max: 200,
            });
          });

        instance
          .get(`project/daily-inspection-quota/${selectedProject}`)
          .then((res) => {
            setInspectionQuota({
              used: res?.data.dailyInspectionQuota,
              max: 2000,
            });
          });
        // console.log("One Project: ", res.data);
        instance
          .get(`/urls/count-urls-inspected/${selectedProject}`)
          .then((res) => {
            setTotalInspectedUrls(res.data.passUrlCount);
          });
        instance.get(`/urls/count-indexed/${selectedProject}`).then((res) => {
          setTotalIndexedUrls(res.data.passUrlCount);
        });
        console.log("totalIndexedUrls :>> ", totalIndexedUrls);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchProjects();
  }, [selectedProject, totalIndexedUrls]);

  useEffect(() => {
    if (project) {
      const urlsTested = totalInspectedUrls || 0;
      const urlsIndexed = project.total_url_indexed_by_project || 0;
      const timeSavedMinutes = urlsTested + urlsIndexed;
      setTimeSaved(timeSavedMinutes);
    }
  }, [project, totalIndexedUrls]);
  console.log("totalInspectedUrls :>> ", totalInspectedUrls);
  useEffect(() => {
    if (selectedProject) {
      // Détruisez le graphique existant s'il y en a un
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      // Créez un nouveau graphique à barres
      const ctx = chartRef.current.getContext("2d");
      chartInstanceRef.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["URLs inspectées", "URLs indexées"],
          datasets: [
            {
              label: "Nombre de pages indexées après inspection",
              data: [totalInspectedUrls || 0, totalIndexedUrls || 0],
              backgroundColor: ["#5253f9", "rgb(190, 75, 219)"],
              borderRadius: 8,
              barPercentage: 0.2, // Ajuster la largeur des barres (60% de la largeur par défaut)
              categoryPercentage: 0.8, // Ajuster l'espace entre les barres (80% de la largeur par défaut)
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                display: false, // Pour afficher les gridlines derrière les barres
                color: "rgba(0, 0, 0, 0)", // Couleur des gridlines
              },
            },
            x: {
              grid: {
                display: false, // Pour afficher les gridlines derrière les barres
                color: "rgba(0, 0, 0, 0)", // Couleur des gridlines
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                font: {
                  family: "Inter", // Changer la police à "Inter"
                  size: 14, // Changer la taille de police
                },
              },
            },
            responsive: true,
          },
        },
      });
    }
  }, [selectedProject, totalIndexedUrls, totalInspectedUrls]);

  console.log("selectedProject :>> ", selectedProject);
  return (
    <div className="homepage">
      {selectedProject ? (
        <>
          <h1>{project.name}</h1>
          <h2>Retrouvez toutes les statistiques de votre projet</h2>
          <div className="all-stats">
            <div className="container-stats">
              <img src={search} alt="search" />
              <div className="img-p">
                <span>URL testées</span>
                <p>{totalInspectedUrls || 0} </p>
              </div>
            </div>
            <div className="container-stats">
              <img src={satellite} alt="satellite" />
              <div className="img-p">
                <span>URL indexées</span>
                <p>{totalIndexedUrls || 0}</p>
              </div>
            </div>
            <div className="container-stats">
              <img src={rocket} alt="rocket" />
              <div className="img-p">
                <span>URL poussées à l'indexation</span>
                <p>{project.total_url_indexed_by_project || 0}</p>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="container-stats">
              <img src={rocket} alt="rocket" />
              <div className="img-p">
                <span>Temps gagné</span>
                <p>{timeSaved || 0} minute(s)</p>
              </div>
            </div>
            <Stats
              inspectionQuota={inspectionQuota}
              indexationQuota={indexationQuota}
            />
          </div>
          <div className="chart-container">
            <canvas ref={chartRef} width="400" height="200"></canvas>
          </div>
        </>
      ) : (
        <div>
          <h1>Bienvenue sur Space Indexer</h1>
          <p>Veuillez créer un projet pour commencer.</p>
        </div>
      )}
    </div>
  );
};

export default Homepage;
