import React, { useEffect, useState } from "react";
import NavInspection from "../components/NavInspection";
import { useParams } from "react-router-dom";
import instance from "../axios/global";
import Papa from "papaparse";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import ReactPaginate from "react-paginate";
import link from "../assets/link-out.svg";
import wrong from "../assets/false.svg";
import valid from "../assets/valid.svg";
import { saveAs } from "file-saver";
import { Checkbox, Select } from "@mantine/core";

const SuiviInspection = () => {
  const projectId = useParams();
  const [urlsByProject, setUrlsByProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  const itemsPerPage = 100; // Number of items per page
  const parisTimeZone = "Europe/Paris";
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(1);
  const [selectedUrls, setSelectedUrls] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const fetchUrlsByProject = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/urls/inspection/${projectId.id}`);
        setUrlsByProject(res?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchUrlsByProject();
  }, [projectId.id]);

  const formatDateTime = (dateTimeString) => {
    const zonedDate = utcToZonedTime(new Date(dateTimeString), parisTimeZone);
    return format(zonedDate, "dd/MM/yy", { timeZone: parisTimeZone });
  };

  const formatURL = (url) => {
    const urlObject = new URL(url);
    const path = urlObject.pathname;
    return path;
  };

  // Fonction pour filtrer les URL
  const filterUrls = (urls) => {
    return urls.filter((url) => {
      if (filterStatus === "all") {
        return url.url.includes(searchTerm);
      }
      return (
        ((url.statut === "PASS" && filterStatus === "indexee") ||
          (url.statut !== "PASS" && filterStatus === "nonindexee")) &&
        url.url.includes(searchTerm)
      );
    });
  };

  // Filtrer les URL en fonction de la recherche et de la pagination
  const filteredUrls = filterUrls(urlsByProject);

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredUrls.slice(offset, offset + itemsPerPage);

  const pageCount = Math.ceil(filteredUrls.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // Inverser l'ordre de tri si la même colonne est cliquée à nouveau
      setSortDirection(sortDirection === 1 ? -1 : 1);
    } else {
      // Trier en ordre ascendant par défaut
      setSortedColumn(columnName);
      setSortDirection(1);
    }
  };

  const sortedItems = currentItems.slice().sort((a, b) => {
    if (sortedColumn) {
      if (a[sortedColumn] < b[sortedColumn]) {
        return -sortDirection;
      }
      if (a[sortedColumn] > b[sortedColumn]) {
        return sortDirection;
      }
    }
    return 0;
  });

  const filteredItems = sortedItems.filter((url) => {
    return url.url.includes(searchTerm);
  });

  const handleSelectUrl = (urlToSelect) => {
    const updatedUrls = urlsByProject.map((url) => {
      if (url.url === urlToSelect.url) {
        return { ...url, selected: !url.selected };
      }
      return url;
    });
    setUrlsByProject(updatedUrls);
  };

  const handleSelectAll = () => {
    const allSelected = !selectAll;
    setSelectAll(allSelected);

    // Mettez à jour l'état `selected` de toutes les URL
    const updatedUrls = urlsByProject.map((url) => ({
      ...url,
      selected: allSelected,
    }));
    setUrlsByProject(updatedUrls);
  };

  const exportSelectedUrls = () => {
    const selectedUrlsData = filteredItems
      .filter((url) => url.selected)
      .map(
        ({ url, statut, sendingDate, testIndexation, robot, exploration }) => ({
          URL: url,
          Statut: statut,
          "Date d'envoi": formatDateTime(sendingDate),
          TestIndexation: testIndexation,
          Robot: robot,
          Exploration: exploration,
        })
      );

    const csv = Papa.unparse(selectedUrlsData, { header: true });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "selected_urls.csv");
  };

  const optionsStatus = [
    { value: "all", label: "Tous les statuts" },
    { value: "indexee", label: "Indexee" },
    { value: "nonindexee", label: "Non Indexée" },
  ];

  console.log("urlsByProjects :>> ", urlsByProject);

  return (
    <div className="suivi-inspection-page">
      <div className="title-nav">
        <h1>Inspection</h1>
        <h2>Suivi de toutes vos URLs envoyées à l'inspection.</h2>
        <NavInspection />
      </div>
      {urlsByProject.length > 0 ? (
        <div>
          <div className="filter-bar">
            <div className="search-select">
              <input
                className="search-bar"
                type="text"
                placeholder="Rechercher une URL"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Select
                w={150}
                style={{ cursor: "pointer", marginRight: " 12px" }}
                placeholder="Filtrer par statuts"
                color="gray.0"
                id="statusFilter"
                // className="custom-select"
                value={filterStatus}
                data={optionsStatus}
                onChange={(newValue) => setFilterStatus(newValue)}
              />
            </div>
            <button className="btn-export" onClick={exportSelectedUrls}>
              Exporter en CSV
            </button>
          </div>
          <table style={{ minWidth: 1000 }} className="url-table">
            <thead>
              <tr>
                <th className="th-1st">
                  <Checkbox
                    size="xs"
                    type="checkbox"
                    color="dark"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="th-url" onClick={() => handleSort("url")}>
                  Url
                </th>
                <th onClick={() => handleSort("statut")}>Statut</th>
                <th onClick={() => handleSort("sendingDate")}>Date d'envoi</th>
                <th onClick={() => handleSort("testIndexation")}>
                  Test Indexation
                </th>
                <th onClick={() => handleSort("robot")}>Robot</th>
                <th onClick={() => handleSort("exploration")}>Exploration</th>
                {/* <th onClick={() => handleSort("lastCrawled")}>Dernier crawl</th> */}
                <th className="th-last">SC</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((url, index) => (
                <tr key={index}>
                  <td className="td-1st">
                    <Checkbox
                      size="xs"
                      type="checkbox"
                      color="gray"
                      checked={url.selected}
                      onChange={() => handleSelectUrl(url)}
                    />
                  </td>
                  <td className="td-url">{formatURL(url.url)}</td>
                  <td
                    className={url.statut === "PASS" ? "indexee" : "nonindexee"}
                  >
                    {url.statut === "PASS" ? "Indexée" : "Non Indexée"}
                  </td>
                  <td>{formatDateTime(url.sendingDate)}</td>
                  <td>{url.testIndexation}</td>
                  <td>
                    {" "}
                    {url.robot === "ALLOWED" ? (
                      <img src={valid} alt="valid" />
                    ) : (
                      <img src={wrong} alt="wrong" />
                    )}
                  </td>
                  <td>{url.exploration}</td>
                  {/* <td>{url.lastCrawled}</td> */}
                  <td className="td-last">
                    <a
                      href={url.inspectionResultLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={link} alt="link" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            previousLabel={"←"}
            nextLabel={"→"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      ) : (
        <p className="no-data">Aucune URL à afficher.</p>
      )}
    </div>
  );
};

export default SuiviInspection;
