import React, { useEffect, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/logo-space-indexer.svg";

import { useParams } from "react-router-dom";
import { AuthContext } from "../auth/auth";
import sidebarclose from "../assets/sidebar-close.svg";
import sidebaropen from "../assets/sidebar-open.svg";
import home from "../assets/home.svg";
import earth from "../assets/earth.svg";
import inspect from "../assets/inspect.svg";
import settings from "../assets/settings.svg";
import logout from "../assets/logout.svg";
import doc from "../assets/documentation.svg";

const Sidebar = ({ selectedProject }) => {
  const { id } = useParams();
  const [arrow, setArrow] = useState(true);
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    setAuthState({
      name: "",
      email: "",
      id: "",
      status: false,
    });
    navigate("/");
  };

  //console.log("id.id :>> ", id.id);
  //console.log("id :>> ", id);
  console.log("authState.id :>> ", authState.id);

  console.log("arrow :>> ", arrow);

  return (
    <aside className={arrow ? "aside-navsidebar" : "aside-close"}>
      <div className="arrow">
        {arrow ? (
          <img
            src={sidebaropen}
            alt={sidebaropen}
            onClick={() => setArrow(!arrow)}
            className="arrow-sidebar"
          />
        ) : (
          <img
            src={sidebarclose}
            alt={sidebarclose}
            onClick={() => setArrow(!arrow)}
            className="arrow-sidebar"
          />
        )}
      </div>
      <div className="nav-logo">
        {" "}
        <img onClick={() => navigate("/home")} src={logo} alt="logo-S.I" />
        <h1 className={arrow ? "" : "display-none"}>
          Space<span>Indexer</span>
        </h1>
      </div>

      <nav className="nav-sidebar">
        <NavLink to="/home" className="hover-link">
          <img src={home} alt="home" />
          <span className={arrow ? "nav-links" : "display-none"}>Home</span>
        </NavLink>
        <NavLink to={`/inspection/${selectedProject}`} className="hover-link">
          <img src={inspect} alt="inspection" />
          <span className={arrow ? "nav-links" : "display-none"}>
            Inspection
          </span>
        </NavLink>
        <NavLink to={`/indexation/${selectedProject}`} className="hover-link">
          <img src={earth} alt="indexation" />
          <span className={arrow ? "nav-links" : "display-none"}>
            Indexation
          </span>
        </NavLink>

        <NavLink to={`/parametres/${selectedProject}`} className="hover-link">
          <img src={settings} alt="settings" />
          <span className={arrow ? "nav-links" : "display-none"}>
            Paramètres
          </span>
        </NavLink>

        <NavLink to="/documentation" className="hover-link">
          <img src={doc} alt="documentation" />
          <span className={arrow ? "nav-links" : "display-none"}>
            Documentation
          </span>
        </NavLink>
      </nav>

      <footer>
        <div className="logout" onClick={handleLogout}>
          <img src={logout} alt="logout" />
          <span className={arrow ? "nav-links" : "display-none"}>Logout</span>
        </div>
      </footer>
    </aside>
  );
};

export default Sidebar;
