import React from "react";
import {
  RingProgress,
  Text,
  SimpleGrid,
  Paper,
  Center,
  Group,
} from "@mantine/core";

const Stats = ({ inspectionQuota, indexationQuota }) => {
  const data = [
    {
      label: "Quota Indexation",
      stats: `${indexationQuota.used}/${indexationQuota.max}`,
      progress: (indexationQuota.used / indexationQuota.max) * 100,
      color: "violet",
      // icon: "up",
    },
    {
      label: "Quota Inspection",
      stats: `${inspectionQuota.used}/${inspectionQuota.max}`,
      progress: (inspectionQuota.used / inspectionQuota.max) * 100,
      color: "grape",
      // icon: "up",
    },
  ];
  const stats = data.map((stat) => {
    return (
      <Paper
        withBorder
        radius="md"
        p="xs"
        key={stat.label}
        style={{
          width: "279px",
          height: "102px",
          marginLeft: "20px",
        }}
      >
        <Group>
          <RingProgress
            size={80}
            roundCaps
            thickness={8}
            sections={[{ value: stat.progress, color: stat.color }]}
          />

          <div>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
              {stat.label}
            </Text>
            <Text weight={700} size="xl">
              {stat.stats}
            </Text>
          </div>
        </Group>
      </Paper>
    );
  });

  return <div style={{ display: "flex" }}>{stats}</div>;
};
export default Stats;
