import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavIndexation from "../components/NavIndexation";
import instance from "../axios/global";
import Papa from "papaparse";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import wrong from "../assets/false.svg";
import valid from "../assets/valid.svg";
import { saveAs } from "file-saver";
import { Checkbox, Select } from "@mantine/core";
import ReactPaginate from "react-paginate";

const SuiviIndexation = () => {
  const projectId = useParams();
  const [urlsByProject, setUrlsByProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  const itemsPerPage = 100; // Number of items per page
  const parisTimeZone = "Europe/Paris";
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(1);
  const [selectedUrls, setSelectedUrls] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const fetchUrlsByProject = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/urls/indexation/${projectId.id}`);
        setUrlsByProject(res?.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchUrlsByProject();
  }, [projectId.id]);

  const formatDateTime = (dateTimeString) => {
    const zonedDate = utcToZonedTime(new Date(dateTimeString), parisTimeZone);
    return format(zonedDate, "dd/MM/yyyy", { timeZone: parisTimeZone });
  };

  const formatURL = (url) => {
    const urlObject = new URL(url);
    const path = urlObject.pathname;
    return path;
  };

  // const handleExportCSV = () => {
  //   const exportData = urlsByProject.map((url) => ({
  //     URL: url.url,
  //     "Date d'envoi": url.sendingDate,
  //     statut: url.statut,
  //   }));
  //   const csv = Papa.unparse(exportData);
  //   const blob = new Blob([csv], { type: "text/csv" });

  //   const url = URL.createObjectURL(blob);

  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "URLs_Indexation.csv"; // Ajoutez cet attribut

  //   a.click();
  //   URL.revokeObjectURL(url);
  // };

  const offset = currentPage * itemsPerPage;
  const currentItems = urlsByProject.slice(offset, offset + itemsPerPage);

  const pageCount = Math.ceil(urlsByProject.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // Inverser l'ordre de tri si la même colonne est cliquée à nouveau
      setSortDirection(sortDirection === 1 ? -1 : 1);
    } else {
      // Trier en ordre ascendant par défaut
      setSortedColumn(columnName);
      setSortDirection(1);
    }
  };

  const sortedItems = currentItems.slice().sort((a, b) => {
    if (sortedColumn) {
      if (a[sortedColumn] < b[sortedColumn]) {
        return -sortDirection;
      }
      if (a[sortedColumn] > b[sortedColumn]) {
        return sortDirection;
      }
    }
    return 0;
  });

  const handleSelectUrl = (urlToSelect) => {
    const updatedUrls = urlsByProject.map((url) => {
      if (url.url === urlToSelect.url) {
        return { ...url, selected: !url.selected };
      }
      return url;
    });
    setUrlsByProject(updatedUrls);
  };

  const handleSelectAll = () => {
    const allSelected = !selectAll;
    setSelectAll(allSelected);

    // Mettez à jour l'état `selected` de toutes les URL
    const updatedUrls = urlsByProject.map((url) => ({
      ...url,
      selected: allSelected,
    }));
    setUrlsByProject(updatedUrls);
  };

  const filteredItems = sortedItems.filter((url) => {
    return url.url.includes(searchTerm);
  });

  const exportSelectedUrls = () => {
    const selectedUrlsData = urlsByProject
      .filter((url) => url.selected)
      .map(({ url, statut, sendingDate }) => ({
        URL: url,
        "Date d'envoi": formatDateTime(sendingDate),
        Statut: statut,
      }));

    const csv = Papa.unparse(selectedUrlsData, { header: true });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "selected_urls.csv");
  };

  return (
    <section className="suivi-indexation">
      <div className="title-nav">
        <h1>Indexation</h1>
        <h2>
          Retrouvez ici toutes les URLs qui ont été envoyées à l’indexation.
        </h2>
        <NavIndexation />
      </div>

      {urlsByProject.length > 0 ? (
        <div>
          <div className="search-export">
            <input
              className="search-bar"
              type="text"
              placeholder="Rechercher une URL"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="btn-export" onClick={exportSelectedUrls}>
              Exporter en CSV
            </button>
          </div>
          <table style={{ minWidth: 800 }} className="url-table">
            <thead>
              <tr>
                <th className="th-1st">
                  <Checkbox
                    size="xs"
                    type="checkbox"
                    color="dark"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="th-url" onClick={() => handleSort("url")}>
                  Url
                </th>
                <th onClick={() => handleSort("sendingDate")}>Date d'envoi</th>
                <th className="th-last" onClick={() => handleSort("statut")}>
                  Statut
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((url, index) => (
                <tr key={index}>
                  <td className="td-1st">
                    <Checkbox
                      size="xs"
                      type="checkbox"
                      color="gray"
                      checked={url.selected}
                      onChange={() => handleSelectUrl(url)}
                    />
                  </td>
                  <td className="td-url">{formatURL(url.url)}</td>
                  <td>{formatDateTime(url.sendingDate)}</td>
                  <td
                    style={{ paddingRight: "15px" }}
                    className="statut-demande"
                  >
                    {url.statut}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            previousLabel={"←"}
            nextLabel={"→"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      ) : (
        <p className="no-data">Aucune URL à afficher.</p>
      )}
    </section>
  );
};

export default SuiviIndexation;
