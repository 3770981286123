import React from "react";
import { Accordion } from "@mantine/core";
import search from "../assets/searching.svg";
import domain from "../assets/choose-domain.jpg";
import indexationvideo from "../assets/indexation.mov";
import inspectionvideo from "../assets/inspection.mov";
import creationvideo from "../assets/creationprojet.mov";
import inspect from "../assets/inspect.svg";
import indexation from "../assets/earth.svg";
import createproject from "../assets/Creerunprojet1.jpg";
import createproject2 from "../assets/Creerunprojet2.jpg";
import createproject3 from "../assets/Creerunprojet3.jpg";
import hamburger from "../assets/MenuHamburger.jpg";
import bibliotheque from "../assets/Bibliotheque.jpg";
import searchGSC from "../assets/RechercherGoogleSearchConsoleAPI.jpg";
import clickGSC from "../assets/CliquerGoogleSearchConsoleAPI.jpg";
import activeAPI from "../assets/ActiverAPI.jpg";
import createAccess from "../assets/CreerAcces.jpg";
import createAccount from "../assets/CreerCompte.jpg";
import roleOwner from "../assets/RoleOwner.jpg";
import selectAccount from "../assets/Selectionnerlecompte.jpg";
import clickKey from "../assets/Cliquerclefs.jpg";
import createKey from "../assets/CreerClefs.jpg";
import createKeyJSON from "../assets/CreerClefsJson.jpg";
import SCproperties from "../assets/SCProprietes.jpg";
import users from "../assets/utilisateur.jpg";
import validateUser from "../assets/Validerutilisateur.jpg";
import addUsers from "../assets/ajouterutilisateur.jpg";

const Documentation = () => {
  return (
    <div className="documentation-page">
      <h1>Documentation</h1>
      <Accordion radius="sm" defaultValue="creer-cle-api">
        <Accordion.Item value="créer une clé API">
          <Accordion.Control icon=<img src={search} alt="" />>
            Comment créer une clé API
          </Accordion.Control>
          <Accordion.Panel>
            <div className="container-tutorials">
              <h3>Activer l’API Web Search Indexing API</h3>
              <span>
                → Connectez-vous à
                <a
                  href="https://console.developers.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Cloud Console
                </a>
              </span>
              <span>→ Créer un projet et selectionner le</span>
              <img src={createproject} alt="creerprojet" />
              <img src={createproject2} alt="creerprojet2" />
              <img src={createproject3} alt="creerprojet3" />

              <span>
                Après avoir selectionné votre projet, Cliquez sur menu de
                navigation principal (cliquez sur l’icône en forme de
                hamburger),
              </span>
              <img src={hamburger} alt="menu-burger" />
              <span>→ Cliquez sur API et services, Bibliothèque, </span>
              <img src={bibliotheque} alt="bibliotheque" />
              <span>→ Recherchez Google Search Console API</span>
              <img src={searchGSC} alt="search-GSC-API" />
              <span>→ Cliquez dessus dans les résultats de la recherche</span>
              <img src={clickGSC} alt="clicGSC" />
              <span>→ Activez l’API</span>
              <img src={activeAPI} alt="activeAPI" />
              <span>
                → Vous serez redirigé après l’activation vers cette API, Depuis
                le menu de gauche, cliquez sur “Credentials”, puis dans le
                panneau de droite, cliquez sur le bouton “Create Credentials”
                Choisissez l’option “Service Account.
              </span>
              <img src={createAccess} alt="access" />
              <span>
                → Saisissez le “Space Indexer” et la description “outil Yuri and
                Neil pour indexation” puis cliquez sur “Create and Continue”.
              </span>
              <img src={createAccount} alt="createAccount" />
              <span>→ Sélectionner “Owner”</span>
              <img src={roleOwner} alt="owner" />
              <span>
                → Cliquer sur “Done”, Après être redirigé, Copiez l’adresse mail
                créé et Cliquez sur le compte.
              </span>
              <img src={selectAccount} alt="selectAccount" />
              <span>→ Cliquer sur “Keys”</span>
              <img src={clickKey} alt="clic-Key" />
              <span>
                → Créer une nouvelle clefs en cliquant sur “Add Key” et “Create
                New Key” et sélectionner “Json” et enregistrer votre clef
              </span>
              <img src={createKey} alt="create-key" />
              <img src={createKeyJSON} alt="create-JSON" />
              <span>
                → Envoyez le fichier téléchargé à votre consultant SEO
              </span>
              <div>
                <h3>
                  Ajoutez votre compte de service en tant que propriétaire de
                  site. (Via Google Search Console)
                </h3>
                <div className="SC-parameters">
                  <div className="step-parameters">
                    <span>
                      1. Allez maintenant sur{" "}
                      <a
                        href="https://search.google.com/search-console/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Search Console.
                      </a>{" "}
                    </span>
                    <br />
                    <span>
                      2. Sélectionnez la propriété de site Web souhaitée dans la
                      liste.
                    </span>
                    <br />
                    <span>
                      3. Ensuite, dans le panneau de navigation de gauche,
                      cliquez sur Paramètres.
                    </span>
                  </div>

                  <div>
                    <img src={SCproperties} alt="" />
                  </div>
                </div>
              </div>
              <span>
                Sous Paramètres de propriété, cliquez sur Utilisateurs et
                autorisations.
              </span>
              <img src={users} alt="users-auth" />
              <span>→ Cliquer sur “Ajouter un utilisateur”</span>
              <img src={addUsers} alt="add-users" />
              <span>
                → Cliquez sur le bouton “Ajouter un utilisateur”. Saisissez
                l’adresse e-mail copié précédemment.
              </span>
              <img src={validateUser} alt="valid-users" />
            </div>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Accordion radius="sm" defaultValue="creer-projet">
        <Accordion.Item value="Comment créer un projet">
          <Accordion.Control icon=<img src={search} alt="" />>
            Comment créer un projet
          </Accordion.Control>
          <Accordion.Panel>
            <div className="container-video-text">
              <div className="container-video">
                <video autoPlay loop muted className="video">
                  <source src={creationvideo} type="video/mp4" />
                  Votre navigateur ne prend pas en charge la vidéo.
                </video>
              </div>
              <div className="container-text">
                <div className="div-domain">
                  <div className="p-domain">
                    <span>
                      Pour créer un nouveau projet, il vous faut au préalable
                      avoir créer une clé api(voir doc).
                    </span>
                    <h4>1. Nom du projet :</h4>
                    <p>→ Entrez le nom de votre projet</p>
                    <h4>2. Domaine : </h4>
                    <p>
                      Important : Assurez-vous que l'URL saisie ici est
                      exactement la même que celle que vous avez déclarée dans
                      la Search Console de Google. exemple :
                    </p>

                    <p>
                      → Si sur ma search console, mon site a été entré en tant
                      que “domaine”, je déclare dans les parametres de mon
                      projet sc-domain: suivi du nom de domaine. exemple :
                      sc-domain:onlyrooftop.com
                      <br />→ Si j’ai une url, je l’a copie a l’identique.
                      exemple : https://onlyrooftop.com/ (Le '/' final est
                      important pour garantir la correspondance correcte.)
                    </p>
                    <h4>3. Clé API :</h4>
                    <p>
                      → Collez ici votre clé API que vous avez préalablement
                      générée.
                    </p>
                  </div>
                  <img width={250} src={domain} alt="choose-domain" />
                </div>
              </div>
            </div>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Accordion radius="sm" defaultValue="inspection">
        <Accordion.Item value="inspection">
          <Accordion.Control icon=<img src={inspect} alt="" />>
            Inspection
          </Accordion.Control>
          <Accordion.Panel>
            <div className="container-video-text">
              <div className="container-text">
                <div className="div-inspection">
                  <h3>Inspection</h3>
                  <p>
                    Envoyez jusqu’a 2000 URLs par jour à tester. (reset à 08h00
                    tous les jours)
                  </p>
                  <p>Coller vos URLs séparées par un espace entre elles.</p>
                </div>
                <div className="div-suivi">
                  <h3>Suivi Inspection</h3>
                  <p>Retrouvez toutes vos URLs envoyées a l’inspection</p>
                  <ul>
                    <li>→ URL : Url traitée.</li>
                    <li>→ Date d’envoi : Date de l’envoi a l’inspection.</li>
                    <li>
                      → Test indexation : URL existante sur le site ou non.
                    </li>
                    <li>→ Statut d’indexation : URL indexée ou non.</li>
                    <li>→ Robot : Autorisé ou non par le robot.</li>
                    <li>
                      → Indexation Autorisée : No Index présent sur la page.
                    </li>
                    <li>→ SC : Lien vers la Search Console.</li>
                  </ul>
                  <p>
                    Possibilité d’exportez en CSV en sélectionnant vos URLs pour
                    les pousser en indexation
                  </p>
                </div>
              </div>
              <div className="container-video">
                <video autoPlay loop muted className="video">
                  <source src={inspectionvideo} type="video/mp4" />
                  Votre navigateur ne prend pas en charge la vidéo.
                </video>
              </div>
            </div>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Accordion radius="sm" defaultValue="indexation">
        <Accordion.Item value="indexation">
          <Accordion.Control icon=<img src={indexation} alt="" />>
            Indexation
          </Accordion.Control>
          <Accordion.Panel>
            <div className="container-video-text">
              <div className="container-video">
                <video
                  autoPlay
                  loop
                  muted
                  className="video"
                  style={{ width: "700px" }}
                >
                  <source src={indexationvideo} type="video/mp4" />
                  Votre navigateur ne prend pas en charge la vidéo.
                </video>
              </div>
              <div className="container-text">
                <div className="div-inspection">
                  <h3>Indexation</h3>
                  <p>
                    Envoyez jusqu’a 200 URLs par jour à l'indexation. (reset à
                    08h00 tous les jours)
                  </p>
                  <p>Coller vos URLs séparées par un espace entre elles.</p>
                </div>
                <div className="div-suivi">
                  <h3>Suivi Indexation</h3>
                  <p>Retrouvez toutes vos URLs envoyées a l’indexation</p>
                  <ul>
                    <li>→ URL : Url traitée.</li>
                    <li>→ Date d’envoi : Date de l’envoi a l’indexation.</li>

                    <li>→ Statut d’indexation : URL indexée ou non.</li>
                  </ul>
                  <p>
                    Possibilité d’exportez en CSV en sélectionnant vos URLs pour
                    vérifier leur indexation dans le module inspection
                  </p>
                </div>
              </div>
            </div>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Documentation;
