import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    accessToken: localStorage.getItem("accessToken"),
  },
});

// Intercepteur pour gérer les erreurs de réponse
instance.interceptors.response.use(
  (response) => {
    // Si la réponse est OK (statut 2xx), renvoyer la réponse telle quelle
    return response;
  },
  (error) => {
    // Si une erreur se produit, vérifiez si le backend a renvoyé un message d'erreur
    if (error.response && error.response.data && error.response.data.error) {
      // Si le backend a renvoyé un message d'erreur, utilisez-le
      return Promise.reject(error.response.data.error);
    } else {
      // Sinon, renvoyez une erreur générique
      return Promise.reject("Une erreur est survenue lors de la requête.");
    }
  }
);

export default instance;
