import React, { useEffect, useState } from "react";
import useProjectContext from "../hooks/useProjectContext";
import instance from "../axios/global";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

const Settings = ({ setSelectedProject }) => {
  const projectId = useParams();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [keyId, setKeyId] = useState("");
  const { projectName, setProjectName, websiteUrl, setWebsiteUrl } =
    useProjectContext();

  console.log("projectId :>> ", projectId.id);
  const navigate = useNavigate();
  const [opened, { close, open }] = useDisclosure(false);

  const clientSchema = yup.object().shape({
    name: yup.string().required("Veuillez renseigner le nom du nouveau projet"),
    website_url: yup
      .string()
      .test(
        "is-valid-website-url",
        'Veuillez entrer un sc-domain: ou une URL valide commençant par "https://" et se terminant par "/"',
        function (value) {
          if (value.startsWith("sc-domain:")) {
            return true;
          }
          if (value.startsWith("https://") && value.endsWith("/")) {
            return true;
          }
          return false;
        }
      )
      .min(3, "L'Url ou domaine du projet doit comporter au moins 3 caractères")
      .max(45, "L'Url ou domaine du projet ne doit pas exceder 25 caractères")
      .required("Veuillez renseigner le domaine ou l'url du projet"),
    apiKey: yup
      .string()
      .test(
        "contains-private-and-project",
        "Vérifiez votre clé API, elle semble incorrect",
        function (value) {
          return (
            value.includes("private_key_id") &&
            value.includes("project_id") &&
            value.includes("private_key")
          );
        }
      )
      .required(
        "L'espace est immense, mais cette clé est essentielle. Veuillez la saisir."
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(clientSchema),
  });

  useEffect(() => {
    const fetchProjectAndKey = async () => {
      setLoading(true);
      try {
        // 1. Récupérer les informations du projet
        const projectResponse = await instance.get(
          `/project/oneproject/${projectId.id}`
        );
        setProjectName(projectResponse.data.name);
        setWebsiteUrl(projectResponse.data.website_url);

        // 2. Récupérer la clé API associée à ce projet
        const apiKeyResponse = await instance.get(
          `/key/byproject/${projectId.id}`
        );

        setKey(apiKeyResponse.data.key);
        setKeyId(apiKeyResponse.data.id);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchProjectAndKey();
  }, [projectId.id]);

  const handleSubmitUpdate = async () => {
    //e.preventDefault();

    try {
      // Envoyez d'abord les données de projet
      const projectUpdateResponse = await instance.put(
        `/project/update/${projectId.id}`,
        {
          name: projectName,
          website_url: websiteUrl,
        }
      );

      console.log(
        "Mise à jour du projet réussie : ",
        projectUpdateResponse.data
      );
      console.log("key.id :>> ", keyId);
      console.log("key :>> ", key);
      // Ensuite, envoyez les données de la clé
      const keyUpdateResponse = await instance.put(`/key/update/${keyId}`, {
        key: key,
      });

      console.log("Mise à jour de la clé réussie : ", keyUpdateResponse.data);

      // Naviguez ou affichez un message de succès ici
      navigate("/home");
      toast.success("Vos paramètres ont bien été modifiés.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    } catch (error) {
      // Gérez les erreurs ici
      console.error("Erreur lors de la mise à jour : ", error);

      // Affichez une notification d'erreur
      toast.error(error.response.data.msg || "Une erreur s'est produite", {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const handleSubmitDelete = async () => {
    try {
      const deleteProject = await instance.delete(
        `project/delete/${projectId.id}`
      );
      console.log("Suppression du projet réussie : ", deleteProject.data);

      navigate("/home");
      toast.success("Votre projet a bien été supprimé.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      setSelectedProject(null);
    } catch (error) {
      // Gérez les erreurs ici
      console.error("Erreur lors de la suppression: ", error);

      // Affichez une notification d'erreur
      toast.error(
        error.response.data.msg ||
          "Une erreur s'est produite lors de la suppresion du projet",
        {
          position: toast.POSITION.TOP_LEFT,
        }
      );
    }
  };

  return (
    <div className="creation-projet-page">
      <div className="h2-delete">
        <h1>Paramètres</h1>
        <Modal
          opened={opened}
          onClose={close}
          size="auto"
          title="Supprimer mon projet"
        >
          <div className="modal">
            <p>Êtes vous sur de vouloir supprimer votre projet ?</p>
            <div className="btn-div">
              <button className="cancel-btn" onClick={close}>
                annuler
              </button>
              <button className="delete-btn" onClick={handleSubmitDelete}>
                supprimer
              </button>
            </div>
          </div>
        </Modal>

        <button className="delete-btn" onClick={open}>
          Supprimer mon projet
        </button>
      </div>
      <h2>Tous les paramètres de votre projet</h2>

      <form action="" onSubmit={handleSubmit(handleSubmitUpdate)}>
        <div className="container-name-website">
          <div className="label-input">
            <label htmlFor="">Nom du projet</label>
            <input
              type="text"
              name="name"
              id="name"
              {...register("name", {
                required: "Required",
              })}
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value);
              }}
            />
            <span className="warning-msg">
              {errors.name && errors.name.message}
            </span>
          </div>
          <div className="label-input" style={{ marginLeft: "30px" }}>
            <label htmlFor="">Domaine ou URL</label>
            <input
              type="text"
              name="website_url"
              id="website_url"
              {...register("website_url", {
                required: "Required",
              })}
              value={websiteUrl}
              onChange={(e) => {
                setWebsiteUrl(e.target.value);
              }}
            />
            <span className="warning-msg">
              {errors.website_url && errors.website_url.message}
            </span>
          </div>
        </div>
        <div className="container-textarea">
          <label htmlFor="">Clé API d'indexation google</label>
          <textarea
            type="text"
            name="apiKey"
            id="apiKey"
            {...register("apiKey", {
              required: "Required",
            })}
            value={key}
            onChange={(e) => {
              setKey(e.target.value);
            }}
          ></textarea>
          <span className="warning-msg">
            {errors.apiKey && errors.apiKey.message}
          </span>
          <p>
            Pour utiliser l’API d’indexation de Google et générer votre fichier
            clé JSON, veuillez suivre
            <span
              className="guide-link"
              onClick={() => navigate("/documentation")}
            >
              notre documentation
            </span>
          </p>
        </div>

        <button>Modifier</button>
      </form>
    </div>
  );
};

export default Settings;
