import React, { useEffect, useState } from "react";
import NavInspection from "../components/NavInspection";
import { useParams } from "react-router-dom";
import useProjectContext from "../hooks/useProjectContext";
import instance from "../axios/global";
import axios from "axios";
import success from "../assets/success.svg";
import iconerror from "../assets/error.svg";
import { Loader } from "@mantine/core";

const Inspection = () => {
  const projectId = useParams();
  const [urls, setUrls] = useState("");
  const [lastSubmissionTime, setLastSubmissionTime] = useState(null);
  const cooldownPeriod = 60000; // 1 minute en millisecondes
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [invalidUrls, setInvalidUrls] = useState([]);
  const [parsedUrls, setParsedUrls] = useState([]);

  const {
    projectName,
    setProjectName,
    websiteUrl,
    setWebsiteUrl,
    apiKey,
    setApiKey,
  } = useProjectContext();

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/project/oneproject/${projectId.id}`);
        setProjectName(res?.data.name);
        setWebsiteUrl(res?.data.website_url);

        const apiKeyResponse = await instance.get(
          `/key/byproject/${projectId.id}`
        );
        console.log("apiKeyResponse :>> ", apiKeyResponse);
        setApiKey(apiKeyResponse?.data.key);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchSettings();
  }, [projectId.id]);

  useEffect(() => {
    setParsedUrls(urls.split("\n").filter((url) => url.trim() !== ""));
  }, [urls]);

  const isValidUrl = (url) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(url);
  };

  const isMatchingWebsiteUrl = (url, websiteUrl) => {
    // Si le websiteUrl commence par "sc-domain:", vérifiez si l'URL contient la partie après "sc-domain:"
    if (websiteUrl.startsWith("sc-domain:")) {
      const domainPart = websiteUrl.slice("sc-domain:".length);
      return url.includes(domainPart);
    } else {
      // Si le websiteUrl n'est pas une URL complète, vérifiez si l'URL contient le websiteUrl
      return url.includes(websiteUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const currentTime = new Date().getTime();
    setLastSubmissionTime(currentTime);

    if (
      lastSubmissionTime &&
      currentTime - lastSubmissionTime < cooldownPeriod
    ) {
      setError("Veuillez attendre 1 minute avant de soumettre à nouveau.");
      return;
    }
    console.log("websiteUrl ICICICICII:>> ", websiteUrl);
    console.log("currentTime :>> ", currentTime);
    try {
      const parsedUrls = urls.split("\n").filter((url) => url.trim() !== "");

      const invalidUrls = [];

      const validParsedUrls = parsedUrls.filter((url) => {
        const isValid =
          isMatchingWebsiteUrl(url, websiteUrl) && isValidUrl(url);
        if (!isValid) {
          invalidUrls.push(url);
        }

        return isValid;
      });
      if (parsedUrls.length > 600) {
        setError("Vous ne pouvez soumettre que 600 URLs par minute");
        return;
      }
      setLoading(true);
      setMessage("Envoi des URLs pour inspection en cours...");

      setError(null);

      const remainingQuota = await instance.get(
        `/project/daily-inspection-quota/${projectId.id}`
      );

      if (validParsedUrls.length > remainingQuota) {
        setLoading(false);
        setError(
          "Limite quotidienne du quota d'inspection atteinte pour ce projet."
        );
        return; // Arrêtez ici si le quota est dépassé
      }

      console.log("remainingQuota :>> ", remainingQuota);
      console.log(
        "lastSubmissionTime && currentTime - lastSubmissionTime < cooldownPeriod :>> ",
        lastSubmissionTime && currentTime - lastSubmissionTime < cooldownPeriod
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/urls/checking-indexation`,
        {
          websiteUrl: websiteUrl,
          projectId: projectId.id,
          apiKey,
          urls: validParsedUrls,
        }
      );
      for (const url of validParsedUrls) {
        try {
          // Appel à la fonction pour réduire le quota
          const quotaReductionResult = await instance.put(
            `/project/reduce-inspection/${projectId.id}`,
            { count: 1 }
          );

          if (quotaReductionResult.data === "QUOTA_REDUCED") {
            console.log(`Quota réduit avec succès pour l'URL : ${url}`);
          } else if (quotaReductionResult.data === "QUOTA_EXCEEDED") {
            console.log(`Quota dépassé pour l'URL : ${url}`);
            // Vous pouvez traiter le cas où le quota est dépassé pour une URL spécifique ici
          }
        } catch (error) {
          console.log(
            `Erreur lors de la réduction du quota pour l'URL : ${url}`,
            error
          );
        }
      }
      setInvalidUrls(invalidUrls);
      setLoading(false);
      setMessage(response.data.message);

      console.log(`apiKey, urls:`, apiKey, parsedUrls, projectId.id);
      // Handle the response from the backend here
      console.log(`apiKey, urls:`, apiKey, validParsedUrls, projectId.id);

      // Mise à jour du temps de la dernière soumission
      setLastSubmissionTime(new Date().getTime());
      // if (response.data.invalidUrls && response.data.invalidUrls.length > 0) {
      //   setInvalidUrls(response.data.invalidUrls);
      // } else {
      //   setInvalidUrls([]); // Réinitialiser les URLs invalides s'il n'y en a pas
      // }

      console.log("invalidUrls after :>> ", invalidUrls);
    } catch (error) {
      setLoading(false);
      setMessage(null);
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        if (errorData.error) {
          setError("Erreur lors de l'envoi de vos URLs : " + errorData.error);
        } else {
          setError(
            "Erreur lors de l'envoi de vos URLs : " + JSON.stringify(errorData)
          );
        }
      } else {
        setError("Erreur lors de l'envoi de vos URLs : " + error.message);
      }
      // Handle request errors
      console.error(error);
    }
  };

  return (
    <div className="inspection-page">
      <div className="title-nav">
        <h1>Inspection</h1>
        <h2>Envoyez ici vos URLs à tester.</h2>
        <NavInspection />
      </div>
      <section>
        <form onSubmit={handleSubmit}>
          <div className="container-textarea">
            <label>
              Coller jusqu'a 600 URLs par minute. (2000 urls par jour maximum)
            </label>
            <textarea
              style={{ border: "1px solid #DEE5EC", background: "#FFF" }}
              id="urls"
              value={urls}
              onChange={(e) => setUrls(e.target.value)}
              disabled={
                lastSubmissionTime &&
                new Date().getTime() - lastSubmissionTime < cooldownPeriod &&
                urls.split("\n").filter((url) => url.trim() !== "").length > 600
              }
            ></textarea>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <Loader color="violet" size="lg" />
            </div>
          ) : (
            <button
              type="submit"
              disabled={!apiKey || !urls || loading || parsedUrls.length > 600}
            >
              Décollage pour inspection
            </button>
          )}
        </form>
        {parsedUrls.length > 600 && (
          <p className="error-msg">
            <img src={iconerror} alt="error" />
            <span style={{ marginLeft: "5px" }}>
              Vous ne pouvez soumettre que 600 URLs par minute
            </span>
          </p>
        )}
        {message && (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
              maxWidth: "800px",
              color:
                message ===
                "Envoi des URLs pour inspection en cours... Cela peut prendre quelques minutes"
                  ? "black"
                  : "#4e8565",
            }}
          >
            {message !==
              "Envoi des URLs pour inspection en cours... Cela peut prendre quelques minutes" && (
              <img src={success} alt="done" />
            )}
            <span style={{ marginLeft: "5px" }}> {message}</span>
          </p>
        )}
        {error && (
          <p
            className="error-msg"
            style={{ display: "flex", alignItems: "center", maxWidth: "800px" }}
          >
            <img src={iconerror} alt="error" />
            <span style={{ marginLeft: "5px" }}>{error}</span>
          </p>
        )}
        {invalidUrls.length > 0 && (
          <div className="invalid-urls">
            <h3>
              <img src={iconerror} alt="invalid" />
              URLs invalides :
            </h3>
            <ul>
              {invalidUrls.map((url, index) => (
                <li key={index}>{url}</li>
              ))}
            </ul>
          </div>
        )}
      </section>
    </div>
  );
};

export default Inspection;
