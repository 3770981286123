import React from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
const NavInspection = () => {
  const projectId = useParams();
  return (
    <div className="nav-inspection">
      <NavLink to={`/inspection/${projectId.id}`}>Inspecter mes URLs</NavLink>
      <NavLink to={`/suivi-inspection/${projectId.id}`}>
        Suivi Inspection
      </NavLink>
    </div>
  );
};

export default NavInspection;
