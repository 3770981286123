import React, { useEffect, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/auth";
import instance from "../axios/global";
import user from "../assets/user.svg";
import { Select } from "@mantine/core";

const Navbar = ({ selectedProject, onProjectChange, allProjects }) => {
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [allUserProjects, setAllUserProjects] = useState([]);
  const [projectInfos, setProjectInfos] = useState(""); // Vous pouvez laisser cette ligne si elle est utilisée ailleurs dans le composant

  useEffect(() => {
    setAllUserProjects(allProjects);
  }, [allProjects]);

  const handleProjectChange = (event) => {
    const newProject = event.target.value;
    onProjectChange(newProject);
  };

  useEffect(() => {
    const fetchProjectInfos = async () => {
      try {
        const res = await instance.get(`project/oneproject/${selectedProject}`);
        setProjectInfos(res.data);
        navigate(`/home`);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchProjectInfos();
  }, [selectedProject]);

  return (
    <div className="navbar">
      <div className="user-container">
        <img className="img-user" src={user} alt="user" />
        <div className="user-infos">
          <h2> {authState.name}</h2>
          <span>{authState.email}</span>
        </div>
      </div>
      <div className="container-projects">
        <div>
          <button
            className="btn-new-project"
            onClick={() => navigate("/creerprojet")}
          >
            + Nouveau projet
          </button>
        </div>

        <div className="my-projects">
          <Select
            placeholder="Sélectionnez un projet"
            searchable
            data={allProjects.map((project) => ({
              value: project.id,
              label: project.name,
            }))}
            value={selectedProject}
            onChange={(value) => onProjectChange(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
